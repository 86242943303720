<template>
  <div class="navbar-container d-flex content align-items-center">
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="md"
      :hide-footer="true"
      title="Changer son mot de passe"
      lazy
      static
    >
      <b-row class="my-1">
        <b-col :md="12">
          <b-form-input v-model="password"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="d-flex align-items-center justify-content-center text-center">
        <b-button
          variant="primary"
          class="mb-1"
          @click="changePsw()"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span>Changer le mot de passe</span>
        </b-button>
      </b-row>
    </b-modal>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <div class="mr-1">
      <a href="/todo">
        <feather-icon
          :badge="me.tasks"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </a>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ me.firstName }} {{ me.lastName }}
            </p>
            <span class="user-status">{{ me.type }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/avatar_cat.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider /> -->
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="LockIcon"
            class="mr-50"
          />
          <span @click="showModal = true">Mot de passe</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span @click="logout">Se déconnecter</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="ReloadIcon"
            class="mr-50"
          />
          <span @click="reload">Mettre à jour l'app</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BModal, BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import user from '@/auth/user'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,

    BModal,
    BRow,
    BCol,
    BButton,
    BFormInput,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      password: '',
      showModal: false,
    }
  },
  computed: {
    ...mapGetters('admin', ['me']),
  },
  mounted() {
    this.get()
  },
  methods: {
    ...mapActions('admin', ['get']),
    async changePsw() {
      try {
        if (this.password?.length < 6) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Le mot de passe doit faire au moins 6 caractères.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
        } else {
          await this.$http.get('/user/qrcode', { password: this.password })
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.showModal = false
      }
    },
    logout() {
      console.log('logout')
      user.logout()
    },
    reload() {
      window.location.reload()
    },
  },
}
</script>
