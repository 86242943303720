import Vue from 'vue'

import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = accessToken
        }
        return config
      },
      error => {
        const vm = new Vue()
        vm.$bvToast.toast('Une erreur est survenue', {
          title: 'Une erreur est survenue',
          variant: 'danger',
          solid: true,
        })
        Promise.reject(error)
      },
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        if (response?.data?.accessToken) {
          this.setToken(response.data.accessToken)
        }
        if (response?.data?.redirect) {
          window.location = response.data.redirect
        }
        return response.data
      },
      error => {
        const vm = new Vue()
        vm.$bvToast.toast('Une erreur est survenue', {
          title: 'Une erreur est survenue',
          variant: 'danger',
          solid: true,
        })
        if (error && error.response && error.response.status === 401 && !localStorage.getItem('accessToken')) {
          window.location = '/login'
          localStorage.removeItem('accessToken')
        }
        if (error && error.response && error.response.data && error.response.data.message) {
          vm.$bvToast.toast(error?.response?.data?.message, {
            title: 'Une erreur est survenue',
            variant: 'danger',
            solid: true,
          })
        }
        Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  async logout(...args) {
    await this.axiosIns.post(this.jwtConfig.logoutEndpoint, ...args)
    localStorage.removeItem('accessToken')
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
